<template>
    <BaseUiBadge
        :size="size"
        :variant="variant"
        :color="color"
    >
        {{ label }}
    </BaseUiBadge>
</template>

<script lang="ts" setup>
import type { Sizes, Variants, Colors } from '../base/BaseUiBadge.vue'

const {
    label,
    size = 'sm',
    variant,
} = defineProps<{
    label: string | null
    size?: Sizes
    variant?: Variants
}>()

const { t } = useI18n()

const color = computed<Colors | undefined>(() => {
    const map: Record<string, Colors> = {
        [t('tags.sale')]: 'danger',
        [t('tags.new')]: 'success',
        [t('tags.package')]: 'warning',
    }

    return map[label ?? '']
})

</script>

<style lang="scss" scoped>

</style>
