<template>
    <component
        :is="tag"
        :type="tag === 'button' ? 'button' : undefined"
        :class="['mon-card', sizeClasses, {
            [`mon-card--c-${color}`]: color !== 'none',
            [`mon-card--v-${variant}`]: variant !== 'solid',
        }]"
    >
        <slot />
    </component>
</template>

<script lang="ts" setup>

const {
    tag = 'div',
    color = 'primary',
    size = 'md',
    variant = 'solid',
} = defineProps<{
    tag?: 'div' | 'button'
    variant?: 'solid' | 'outline'
    size?: 'md' | 'none'
    color?: 'danger' | 'warning' | 'primary' | 'none'
}>()

const sizeClasses = computed(() => (({
    none: null,
    md: 'p-4',
} satisfies Record<typeof size, string | null>)[size]))

</script>

<style lang="scss" scoped>

.mon-card--c-primary {
    background-color: $mon-c-primary-100;
}

.mon-card--c-warning {
    background-color: $mon-c-warning-200;
}

.mon-card--c-danger {
    background-color: $mon-c-danger-200;
}

</style>
